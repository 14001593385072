import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "How to catch and block a brute force attack on Wordpress xmlrpc.php file",
  "description": "Wordpress xmlrpc.php file allows attackers to try a large number of WordPress\nusername and password login combinations in a single HTTP request.\n",
  "meta": {
    "title": "How to catch and block a brute force attack on Wordpress xmlrpc.php file",
    "description": "Wordpress xmlrpc.php file allows attackers to brute force login in a single HTTP request."
  },
  "tags": ["Wordpress"],
  "publishedAt": "2016-09-01T12:00:00",
  "published": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><em parentName="p">{`A few days ago I was investigating a database error on a Wordpress site that was
just displaying `}<strong parentName="em">{`Error establishing a database connection`}</strong>{` on every page.`}</em></p>
    <p><em parentName="p">{`After restarting the server, the problem was gone but came back quickly and showed a 500 error.`}</em></p>
    <hr></hr>
    <h2>{`Find the problem`}</h2>
    <p>{`First thing I do on the server (Ubuntu 14.04) is a quick health check of resources with `}<inlineCode parentName="p">{`top`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ top
...
PID USER       VIRT    RES    SHR S %CPU %MEM     TIME+ COMMAND
885 nobody   303748  28380  20760 R 20.4  2.7   0:00.35 php-cgi
894 nobody   302396  21136  14864 R 15.3  2.0   0:00.12 php-cgi
865 nobody   307940  46408  34728 R  5.1  4.4   0:00.78 php-cgi
869 nobody   307496  44924  33736 R  5.1  4.3   0:00.70 php-cgi
870 nobody   306776  43232  32688 R  5.1  4.1   0:00.70 php-cgi
874 nobody   305608  39896  30568 R  5.1  3.8   0:00.61 php-cgi
889 nobody   301968  20508  14660 R  5.1  2.0   0:00.21 php-cgi
890 nobody   303480  23932  16500 R  5.1  2.3   0:00.20 php-cgi
897 nobody   300884  17500  12560 R  5.1  1.7   0:00.06 php-cgi
...
`}</code></pre>
    <p>{`Looks like PHP is eating all the server's resources which leaves other processes
such as MySQL, Apache, or even the SSH server, starving.`}</p>
    <p>{`Since PHP is only supposed to be called by the webserver, I want to check what's
going on ports 80 with `}<a parentName="p" {...{
        "href": "https://debian-administration.org/article/222/Monitoring_active_network_connections_with_tcptrack"
      }}><inlineCode parentName="a">{`tcptrack`}</inlineCode></a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`# install tcptrack first
$ sudo apt-get update
$ sudo apt-get install -y tcptrack

# find network interface name
$ ifconfig
...
venet0    Link encap:UNSPEC  HWaddr ...
...

# checking live traffic
$ tcptrack -i venet0 port 80
191.96.249.54:39074 167.114.88.210:80  ESTABLISHED  0s  902 B/s B/s
191.96.249.54:50730 167.114.88.210:80  ESTABLISHED  1s  901 B/s B/s
191.96.249.54:16331 167.114.88.210:80  ESTABLISHED  1s  900 B/s B/s
`}</code></pre>
    <p>{`First column is the origin ip. It looks like somebody is hammering the server with
web requests.`}</p>
    <p>{`Next thing I want to check is webserver's log files to see what site and url is requested:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`# what service is listening on port 80?
$ sudo lsof -i :80 | grep LISTEN
apache2 11371 www-data    4u  IPv6 3269827235      0t0  TCP *:http (LISTEN)

#  then checking apache logs
$ sudo tail -100 /var/log/apache2/access.log
191.96.249.54 - [26/Aug/2016:21:59:56 -0400] POST /xmlrpc.php...
191.96.249.54 - [26/Aug/2016:21:59:57 -0400] POST /xmlrpc.php...
191.96.249.54 - [26/Aug/2016:21:59:57 -0400] POST /xmlrpc.php...
191.96.249.54 - [26/Aug/2016:21:59:57 -0400] POST /xmlrpc.php...
191.96.249.54 - [26/Aug/2016:21:59:58 -0400] POST /xmlrpc.php...
191.96.249.54 - [26/Aug/2016:21:59:58 -0400] POST /xmlrpc.php...
191.96.249.54 - [26/Aug/2016:21:59:59 -0400] POST /xmlrpc.php...
`}</code></pre>
    <p>{`After a quick search I understand it's a `}<a parentName="p" {...{
        "href": "https://blog.cloudflare.com/a-look-at-the-new-wordpress-brute-force-amplification-attack/"
      }}>{`Wordpress brute force attack`}</a>{`
on the `}<inlineCode parentName="p">{`xmlrpc.php`}</inlineCode>{` file.`}</p>
    <p>{`The automated attack is actually DoS-ing the server since it has only 1GB of RAM.`}</p>
    <hr></hr>
    <h2>{`A targeted solution to the problem`}</h2>
    <p>{`First approach is to block the attacker's IP address with `}<a parentName="p" {...{
        "href": "https://doc.ubuntu-fr.org/ufw"
      }}><inlineCode parentName="a">{`ufw`}</inlineCode></a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`# block ip
$ sudo ufw deny from 191.96.249.54 to any

# check if blocked
$ tcptrack -i venet0 port 80
 191.96.249.54:39074   167.114.113.230:80    SYN_SENT     25s    0 B/s
 191.96.249.54:50730   167.114.113.230:80    SYN_SENT     26s    0 B/s
`}</code></pre>
    <p>{`Now the server doesn't answer to the `}<a parentName="p" {...{
        "href": "https://fr.wikipedia.org/wiki/Three-way_handshake"
      }}>{`three-way handshake`}</a>{`
and connection will eventually timeout after 30 seconds.`}</p>
    <hr></hr>
    <h2>{`A more future-proof solution`}</h2>
    <p>{`To prevent the attack from another IP address, one option is to block any request
to the `}<inlineCode parentName="p">{`xmlrpc.php`}</inlineCode>{` file\` from Apache's virtualhost config file:`}</p>
    <pre><code parentName="pre" {...{}}>{`$ sudo nano /etc/apache2/sites-available/000-default.conf

<VirtualHost *:80>
    ...
    <Files "xmlrpc.php">
      Require all denied
    </Files>
    ...
</VirtualHost>
`}</code></pre>
    <p>{`The downside is that it doesn't prevent connections from hitting the webserver and
still eat some resources if the 403 status code doesn't stop the attack.`}</p>
    <p>{`Also, somebody might want to access the file for legitimate use...`}</p>
    <hr></hr>
    <h2>{`A targeted, future-proof solution`}</h2>
    <p>{`To get the best of both solutions, I use `}<a parentName="p" {...{
        "href": "https://github.com/fail2ban/fail2ban"
      }}><inlineCode parentName="a">{`Fail2ban`}</inlineCode></a>{`
to watch log files and automatically block IP addresses based on custom rules.`}</p>
    <p>{`I've posted another article on `}<a parentName="p" {...{
        "href": "/en/blog/how-to-write-custom-fail2ban-rules"
      }}>{`how to create custom Fail2ban rules`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      